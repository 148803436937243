import Curosal from 'components/curosal/Curosal'
import React from 'react'

const Testamenial = () => {
    
  return (
    <div className="testamenial">
        <Curosal/>
    </div>
  )
}

export default Testamenial