import React from 'react'
import { Link } from 'react-router-dom'
import './mainmenu.scss'
const Mainmenu = () => {
  return (
    <div className="mainmenu">
        <span className="title">Main Menu</span>
        <ul className="menu-list">
          
        <Link className='navbar-link' to="/" style={{textDecoration:"none"}}>
            <li>Home</li>
        </Link>
        <Link className='navbar-link' to="/about" style={{textDecoration:"none"}}>
            <li>About Us</li>
        </Link>
        <Link className='navbar-link' to="/facial-surgery" style={{textDecoration:"none"}}>
            <li>FaceSurgery</li>
        </Link>
        <Link className='navbar-link' to="/body-surgery" style={{textDecoration:"none"}}>
            <li>Body Surgery</li>
        </Link>
        <Link className='navbar-link' to="/dentistry" style={{textDecoration:"none"}}>
            <li>Dentistry</li>
        </Link>
        <Link className='navbar-link' to="/hair-transplant" style={{textDecoration:"none"}}>
            <li>Hair Transplant</li>
        </Link>

            
        </ul>
    </div>
  )
}

export default Mainmenu