import React from 'react'

const Featured = () => {
  return (
    <div className="featured">
      
    </div>
  )
}

export default Featured