import axios from "axios";

const BASE_URL = "https://kaddet.eurlprovert.com/api";

export const baseRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

export const formDataRequest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: { "Content-Type": "multipart/form-data" },
});

export const imageRequest = "https://www.kadet-clinic.com/images/";
