
import Contacts from 'components/contacts/Contacts'
import Navbar from 'components/navbar/Navbar'
import Reservation from 'components/reservation/Reservation'
import Footer from 'containers/footer/Footer'
import React from 'react'
import './reserve.scss'

const Reserve = () => {
  return (
    <div className="reserve">
        <Navbar/>
        <div className="contact-container">
         
        <Contacts/><Reservation/>
        </div>
        
        <Footer/>
    </div>
  )
}

export default Reserve