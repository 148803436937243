import Aboutus from "pages/aboutus/Aboutus";
import Contact from "pages/contact/Contact";
import Reserve from "pages/reserve/Reserve";
import Single from "pages/single/Single";
import Single1 from "pages/single/Single1";
import { useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";

function App() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Routes>
        <Route exact path="/">
          <Route index element={<Home />} />

          <Route path="about">
            <Route index element={<Aboutus />} />
          </Route>

          <Route path="facial-surgery">
            <Route index element={<Single title="face" />} />
          </Route>

          <Route path="body-surgery">
            <Route index element={<Single title="body" />} />
          </Route>

          <Route path="dentistry">
            <Route index element={<Single title="dentis" />} />
          </Route>

          <Route path="hair-transplant">
            <Route index element={<Single title="hairtransplant" />} />
          </Route>

          <Route path="bariatric/gastricballoon">
            <Route index element={<Single1 title="gastricballoon" />} />
          </Route>

          <Route path="bariatric/sleevegastrectomy">
            <Route index element={<Single1 title="sleevegastrectomy" />} />
          </Route>

          <Route path="bariatric">
            <Route index element={<Single title="bariatric" />} />
          </Route>

          <Route path="body-surgery/liposuction">
            <Route index element={<Single1 title="liposuction" />} />
          </Route>

          <Route path="body-surgery/tummytunck">
            <Route index element={<Single1 title="tummytunck" />} />
          </Route>

          <Route path="body-surgery/breastreduction">
            <Route index element={<Single1 title="breastreduction" />} />
          </Route>

          <Route path="body-surgery/breastlift">
            <Route index element={<Single1 title="breastlift" />} />
          </Route>

          <Route path="body-surgery/gynecomastia">
            <Route index element={<Single1 title="gynecomastia" />} />
          </Route>

          <Route path="body-surgery/thighlift">
            <Route index element={<Single1 title="thighlift" />} />
          </Route>

          <Route path="body-surgery/bbl">
            <Route index element={<Single1 title="bbl" />} />
          </Route>

          <Route path="body-surgery/armlift">
            <Route index element={<Single1 title="armlift" />} />
          </Route>

          <Route path="dentistry/holywoodsmile">
            <Route index element={<Single1 title="holywoodsmile" />} />
          </Route>

          <Route path="dentistry/orthodontics">
            <Route index element={<Single1 title="orthodontics" />} />
          </Route>

          <Route path="dentistry/gingivaldiseases">
            <Route index element={<Single1 title="gingivaldiseases" />} />
          </Route>

          <Route path="dentistry/dentalimplants">
            <Route index element={<Single1 title="dentalimplants" />} />
          </Route>

          <Route path="hair-transplant/hairtransplant">
            <Route index element={<Single1 title="hairtransplant" />} />
          </Route>
          <Route path="hair-transplant/fue">
            <Route index element={<Single1 title="fue" />} />
          </Route>
          <Route path="hair-transplant/dhi">
            <Route index element={<Single1 title="dhi" />} />
          </Route>
          <Route path="hair-transplant/sapphir">
            <Route index element={<Single1 title="sapphir" />} />
          </Route>

          <Route path="facial-surgery/rhinoplasty">
            <Route index element={<Single1 title="rhinoplasty" />} />
          </Route>

          <Route path="facial-surgery/facelift">
            <Route index element={<Single1 title="facelift" />} />
          </Route>

          <Route path="facial-surgery/otoplasty">
            <Route index element={<Single1 title="otoplasty" />} />
          </Route>

          <Route path="facial-surgery/necklift">
            <Route index element={<Single1 title="necklift" />} />
          </Route>

          <Route path="facial-surgery/chin">
            <Route index element={<Single1 title="chin" />} />
          </Route>

          <Route path="facial-surgery/eyebrowlift">
            <Route index element={<Single1 title="eyebrowlift" />} />
          </Route>

          <Route path="facial-surgery/eyelid">
            <Route index element={<Single1 title="eyelid" />} />
          </Route>
          <Route path="facial-surgery/facefat">
            <Route index element={<Single1 title="facefat" />} />
          </Route>
          <Route path="facial-surgery/neckliposuction">
            <Route index element={<Single1 title="neckliposuction" />} />
          </Route>

          <Route path="contact">
            <Route index element={<Contact />} />
          </Route>

          <Route path="reserve">
            <Route index element={<Reserve />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
