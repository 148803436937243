export const bodysurgerylist = [
  {
    id: 0,
    title: "LIPOSUCTION",
    image: "./assets/images/bodysurgery/0/0.jpg",
    link: "liposuction",
  },
  {
    id: 1,
    title: "abdominoplasty",
    image: "./assets/images/bodysurgery/1/1.jpg",
    link: "tummytunck",
  },
  {
    id: 4,
    title: "BREAST LIFT",
    image: "./assets/images/bodysurgery/4/4.jpg",
    link: "breastlift",
  },
  {
    id: 5,
    title: "BREAST REDUCTION",
    image: "./assets/images/bodysurgery/5/5.jpg",
    link: "breastreduction",
  },
  {
    id: 6,
    title: "GYNECOMASTIA",
    image: "./assets/images/bodysurgery/6/6.jpg",
    link: "gynecomastia",
  },
  {
    id: 7,
    title: "BRAZILIAN BUTT LIFT",
    image: "./assets/images/bodysurgery/7/7.jpg",
    link: "bbl",
  },
  {
    id: 8,
    title: "ARM LIFT",
    image: "./assets/images/bodysurgery/8/8.jpg",
    link: "armlift",
  },
  {
    id: 9,
    title: "INNER THIGH LIFT",
    image: "./assets/images/bodysurgery/9/9.jpg",
    link: "thighlift",
  },
];

export const facesurgerylist = [
  {
    id: 0,
    title: "RHINOPLASTY",
    image: "./assets/images/facialsurgery/0/0.jpg",
    link: "rhinoplasty",
  },

  {
    id: 3,
    title: "EYEBROW LIFT",
    image: "./assets/images/facialsurgery/3/3.jpg",
    link: "eyebrowlift",
  },
  {
    id: 4,
    title: "Eyelid surgery",
    image: "./assets/images/facialsurgery/4/4.jpg",
    link: "eyelid",
  },
  {
    id: 5,
    title: "Neck lift",
    image: "./assets/images/facialsurgery/5/5.jpg",
    link: "necklift",
  },
  {
    id: 1,
    title: "FACE LIFT",
    image: "./assets/images/facialsurgery/1/1.jpg",
    link: "facelift",
  },
  {
    id: 6,
    title: "face fat injection",
    image: "./assets/images/facialsurgery/6/6.jpg",
    link: "facefat",
  },
  {
    id: 2,
    title: "OTOPLASTY",
    image: "./assets/images/facialsurgery/2/2.jpg",
    link: "otoplasty",
  },
  {
    id: 8,
    title: "neck liposuction",
    image: "./assets/images/facialsurgery/7/7.jpg",
    link: "neckliposuction",
  },
  {
    id: 7,
    title: "CHIN BEATIFICATION SURGERY",
    image: "./assets/images/facialsurgery/8/8.jpg",
    link: "chin",
  },
];

export const dentistrylist = [
  {
    id: 0,
    title: "HOLLYWOOD SMILE",
    image: "./assets/images/dentistry/0/0.jpg",
    link: "holywoodsmile",
  },
  {
    id: 1,
    title: "DENTAL IMPLANTS",
    image: "./assets/images/dentistry/1/1.jpg",
    link: "dentalimplants",
  },

  {
    id: 2,
    title: "Gingival Diseases",
    image: "./assets/images/dentistry/2/2.jpg",
    link: "gingivaldiseases",
  },
  {
    id: 3,
    title: "Orthodontics",
    image: "./assets/images/dentistry/3/3.jpg",
    link: "orthodontics",
  },
];

export const hairtransplantlist = [
  {
    id: 0,
    title: "FUE Technique",
    image: "./assets/images/hairtransplant/0/0.jpg",
    link: "fue",
  },

  {
    id: 1,
    title: "DHI Technique",
    image: "./assets/images/hairtransplant/1/1.jpg",
    link: "dhi",
  },

  {
    id: 2,
    title: "saphir technique",
    image: "./assets/images/hairtransplant/2/2.jpg",
    link: "sapphir",
  },
];

export const bariatrictlist = [
  {
    id: 0,
    title: "Gastric balloon",
    image: "./assets/images/bariatric/0/0.jpg",
    link: "gastricballoon",
  },

  {
    id: 1,
    title: "Sleeve gastrectomy",
    image: "./assets/images/bariatric/1/1.jpg",
    link: "sleevegastrectomy",
  },
];
