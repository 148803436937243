import React from "react";
import "./about.scss";
import clinic from "assets/images/surgery.png";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div className="about" id="about">
      <div className="about-container">
        <div className="left">
          <div className="title">
            <span>KADET CLINIC - ABOUT US</span>
          </div>
          <p>
            We are one of the best clinics that offer you multiple medical
            services, hair transplant, plastic surgery and dental treatment in
            Istanbul with our experienced team of more than 20 years. We would
            love to see you in our family as our experience and quality grows
            with the number of happy patients. You are our priority. We are
            convinced that good communication between you and your surgeon is
            essential when planning your treatments. We offer realistic advice
            and the most suitable treatments for you.
          </p>
          <Link
            className="navbar-link"
            to="/about"
            style={{ textDecoration: "none" }}
          >
            <button className="read">Read more</button>
          </Link>
        </div>

        <div className="right">
          <img src={clinic} alt="" className="about-image" />
        </div>
      </div>
    </div>
  );
};

export default About;
