import React from 'react'
import './whoweare.scss'
const Whoweare = () => {
  return (
    <div className="whoweare">
      <div className="whoweare-container">
            <div className="title">
                <span>KADET CLINIC - ABOUT US</span>
            </div>
                <p>We are one of the best clinics that offer you multiple medical services, hair transplant, plastic surgery and dental treatment in Istanbul with our experienced team of more than 20 years. We would love to see you in our family as our experience and quality grows with the number of happy patients.  You are our priority. We are convinced that good communication between you and your surgeon is essential when planning your treatments. We offer realistic advice and the most suitable treatments for you.</p>
                </div>  
    </div>
  )
}

export default Whoweare