import React from 'react'
import './ourservices.scss'
import transport from 'assets/images/transport.jpg'
import surgery from 'assets/images/surgery.png'
import room from 'assets/images/room.png'
import hotel from 'assets/images/hotel.jpg'
import patient from 'assets/images/patient.jpg'
const Ourservices = () => {
  return (
    <div className="ourservices">
        <div className="services-item">
            <div className="services-title">
            Professional Surgeries
            </div>
            <img className='ourservices-image' src={surgery} alt="Avatar" />
            <div class="overlay">
                <div class="services-text">We transfer you from the airport to your hotel by private vehicles.</div>
                
            </div>
        </div>

        <div className="services-item">
            <div className="services-title">
            Operating Rooms
            </div>
            <img className='ourservices-image'src={room} alt="Avatar" />
            <div class="overlay">
                <div class="services-text">Operating rooms equipped with the latest technology equipment</div>
                
            </div>
        </div>

        <div className="services-item">
            <div className="services-title">
            
            Hotel Rooms
            </div>
            <img src={hotel} alt="Avatar" className='ourservices-image'/>
            <div class="overlay">
                <div class="services-text">We perform smooth plastic surgeries with a professional team.</div>
                
            </div>
        </div>

        <div className="services-item">
            <div className="services-title">
            
            Customer Reviews
            </div>
            <img src={patient} alt="Avatar" className='ourservices-image'/>
            <div class="overlay">
                <div class="services-text">We transfer you from the airport to your hotel by private vehicles.</div>
                
            </div>
        </div>

        <div className="services-item">
            <div className="services-title">
            VIP Transfer
            
            </div>
            <img src={transport} alt="Avatar" className='ourservices-image'/>
            <div class="overlay">
                <div class="services-text">We provide customer satisfaction with professional services.</div>
                
            </div>
        </div>


        
    </div>
  )
}

export default Ourservices