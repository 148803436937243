import React from 'react'
import './firstvisit.scss'
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
const Firstvisit = () => {
  return (
    <div className="firstvisit">
        <div className="firstvisit-container">
            <div className="firstvisit-left">
                <h1 className='firstvisit-h1'>If This Is Your First Visit Do Not Be Afraid !</h1>

                <div className="firstvisit-text">
                    <div className="firstvisit-item">
                 
                        <FlightLandIcon className="firstvisit-item-icon"/>
                   
                    <div className="firstvisit-item-box">
                        <div className="firstvisit-item-title">
                            Airport
                        </div>
                        <div className="firstvisit-item-text">
                        The medical assistant will help you to choose the best time and book for your flight.
                        </div>
                    </div>
                    
                    </div>

                    <div className="firstvisit-item">
                    <AirportShuttleIcon className="firstvisit-item-icon"/>
                    <div className="firstvisit-item-box">
                    <div className="firstvisit-item-title">
                       
Transportation
                    </div>
                    <div className="firstvisit-item-text">
                    Our Vip drivers from begining to departure 
                    </div>
                    </div>
                    
                    </div>


                    <div className="firstvisit-item">
                    <ApartmentIcon className="firstvisit-item-icon"/>
                    <div className="firstvisit-item-box">
                    <div className="firstvisit-item-title">
                    Hotel
                    </div>
                    <div className="firstvisit-item-text">
                    Free booking at the best hotels in Istanbul
                    </div>
                    </div>
                    
                    </div>


                    <div className="firstvisit-item">
                    <HeadsetMicIcon className="firstvisit-item-icon"/>
                    <div className="firstvisit-item-box">
                    <div className="firstvisit-item-title">
                    Following After Operation
                    </div>
                    <div className="firstvisit-item-text">
                    The medical assistant will keep in touch with you for one year to check you out the results.
                    </div>
                    </div>
                    
                    </div>
                </div>
            </div>
            <div className="firstvisit-right">

            </div>
            
        </div>
    </div>
  )
}

export default Firstvisit