import React from "react";
import "./contacts.scss";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
const Contacts = () => {
  return (
    <div className="contacts">
      <span className="title">Contacts</span>
      <div className="container">
        <div className="item">
          <CallIcon />
          <span className="item1">
            <a href="tel:+90 537 677 9908">+90 537 677 9908</a>
          </span>
        </div>
        <div className="item">
          <EmailIcon />

          <a
            className="item1"
            href="mailto:contact@kadetclinic.com?subject=Mail from Our Site"
          >
            contact@kadetclinic.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
