import React, { useState } from 'react'
import './floatingbutton.scss'
import AddIcon from '@mui/icons-material/Add';
import cn from "classnames";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
const Floatingbutton = () => {

  const actions = [
      { label: "Call Us", icon: <CallIcon />, onClick:  ()=>window.open('tel:900300400') },
      { label: "Message Us", icon: <EmailIcon />, onClick: ()=>window.open('mailto:contact@kadetclinic.com') },
      { label: "Facebook", icon: <FacebookIcon />, onClick: ()=>window.open('https://www.facebook.com/kadetclinic') },
      { label: "Instagram", icon: <InstagramIcon />, onClick: ()=>window.open('https://www.instagram.com/kadet_clinic') },
    ];
    
  const [open, setOpen] = useState(false);

  const mouseEnter = () => setOpen(true);
  const mouseLeave = () => setOpen(false);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
   <div className="floatingbutton">
    <ul
      className="fab-container"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <li className="fab-button">
        <AddIcon />
      </li>
      {actions.map((action, index) => (
        <li
          style={{ transitionDelay: `${index * 25}ms` }}
          className={cn("fab-action", { open })}
          key={action.label}
          onClick={action.onClick}
        >
          {action.icon}
          <span className="tooltip">{action.label}</span>
        </li>
      ))}
    </ul>
    <div className="floatingbutton-add" onClick={topFunction}>

    <KeyboardArrowUpRoundedIcon/>
    </div>
   </div>
  )
}

export default Floatingbutton