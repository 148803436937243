import Contacts from "components/contacts/Contacts";
import Form from "components/form/Form";
import Navbar from "components/navbar/Navbar";
import Footer from "containers/footer/Footer";
import React from "react";
import "./contact.scss";
const Contact = () => {
  return (
    <div className="contact">
      <Navbar />
      <div className="contact-container">
        <Contacts />
        <Form title="Contact Us" />
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
