import React from 'react'
import './socialmedia.scss'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
const Socialmedia = () => {
  return (
    <div className="socialmedia">
        <FacebookIcon className='icon' onClick={() => window.open(' https://www.facebook.com/kadetclinic')}/>
        <InstagramIcon className='icon' onClick={() => window.open('https://www.instagram.com/kadet_clinic')}/>
        <YouTubeIcon className='youtube'/>
    </div>
  )
}

export default Socialmedia