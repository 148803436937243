import React from 'react'
import './curosal.scss'
import teeth from 'assets/images/teeth.jpeg'
const Curosal = () => {
  return (
    <div className="curosal">
      <div className="curosal-container">
        <div className="carousel">
          <img src={teeth} alt="a" />
          <blockquote className="testimonial">
            <p className="testimonial-text">
              "Professional work with Kadet Clinic."
            </p>
            <p className="testimonial-author">Ahmed Ben Mohamed</p>
          </blockquote>

          <button className="btn btn--left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="btn-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button className="btn btn--right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="btn-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
          <div className="dots">
            <button className="dot dot--fill">&nbsp;</button>
            <button className="dot">&nbsp;</button>
            <button className="dot">&nbsp;</button>
            <button className="dot">&nbsp;</button>
          </div>
        </div>  
      </div>
    </div>

  )
}

export default Curosal