import React, { useState } from "react";
import "./navbar.scss";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const showNavbar = () => setNavbar(!navbar);
  return (
    <div className={navbar ? "navbar-responsive" : "navbar"}>
      <MenuIcon onClick={showNavbar} className="icon" />
      <ul className="menu">
        <Link className="navbar-link" to="/" style={{ textDecoration: "none" }}>
          <li>Home</li>
        </Link>
        <Link
          className="navbar-link"
          to="/about"
          style={{ textDecoration: "none" }}
        >
          <li>About Us</li>
        </Link>

        <Link
          className="navbar-subnav"
          to="#"
          style={{ textDecoration: "none" }}
        >
          Surgery
          <div className="navbar-subnav-content">
            <Link
              className="navbar-link"
              to="/facial-surgery"
              style={{ textDecoration: "none" }}
            >
              <li>Facial Surgery</li>
            </Link>
            <Link
              className="navbar-link"
              to="/body-surgery"
              style={{ textDecoration: "none" }}
            >
              <li>Body Surgery</li>
            </Link>
          </div>
        </Link>

        <Link
          className="navbar-link"
          to="/dentistry"
          style={{ textDecoration: "none" }}
        >
          <li>Dental Treatement</li>
        </Link>
        <Link
          className="navbar-link"
          to="/hair-transplant"
          style={{ textDecoration: "none" }}
        >
          <li>Hair Transplant</li>
        </Link>

        <Link
          className="navbar-link"
          to="/bariatric"
          style={{ textDecoration: "none" }}
        >
          <li>Bariatric Surgeries</li>
        </Link>
      </ul>

      <ul className="menu-responsive">
        <Link className="navbar-link" to="/" style={{ textDecoration: "none" }}>
          <li>Home</li>
        </Link>
        <Link
          className="navbar-link"
          to="/about"
          style={{ textDecoration: "none" }}
        >
          <li>About Us</li>
        </Link>
        <Link
          className="navbar-link"
          to="/facial-surgery"
          style={{ textDecoration: "none" }}
        >
          <li>Facial Surgery</li>
        </Link>
        <Link
          className="navbar-link"
          to="/body-surgery"
          style={{ textDecoration: "none" }}
        >
          <li>Body Surgery</li>
        </Link>

        <Link
          className="navbar-link"
          to="/dentistry"
          style={{ textDecoration: "none" }}
        >
          <li>Dental Treatement</li>
        </Link>
        <Link
          className="navbar-link"
          to="/hair-transplant"
          style={{ textDecoration: "none" }}
        >
          <li>Hair Transplant</li>
        </Link>

        <Link
          className="navbar-link"
          to="/bariatric"
          style={{ textDecoration: "none" }}
        >
          <li>Bariatric Surgeries</li>
        </Link>
      </ul>
      <div className="right-menu">
        <Link
          className="navbar-link"
          to="/contact"
          style={{ textDecoration: "none" }}
        >
          <button className="contact-menu">Contact</button>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
