import Navbar from 'components/navbar/Navbar';
import Footer from 'containers/footer/Footer';
import { Link } from 'react-router-dom';
import {bodysurgerylist, facesurgerylist, dentistrylist, hairtransplantlist, bariatrictlist} from 'utils/list'

import './single.scss'

const Single = ({title}) => {
    var listOfImages 

    switch(title){
        case 'body':{
            listOfImages = bodysurgerylist;
        }break;

        case 'face':{
            listOfImages = facesurgerylist;
        }break;

        case 'dentis':{
            listOfImages = dentistrylist;
        }break;

        case 'bariatric':{
          listOfImages = bariatrictlist;
        }break;


        case 'hairtransplant':{
          listOfImages = hairtransplantlist;
      }break;
    }


  return (
    <div className="single">
        <Navbar/>
        <div className="single-container">
              {
                listOfImages.map((e) =>   
                <Link className='navbar-link' to={e.link} style={{textDecoration:"none"}}>
                  <div className='single-image-container' key={e.id}>
                        <div className="single-title" >
                            {e.title}
                        </div>
                    <img className='single-image' src={e.image} alt="info"></img>
                  </div>
                  </Link>
                )
              }
          </div>
        
        <Footer/>
    </div>
  )
}

export default Single