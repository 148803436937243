import React from 'react'
import { Link } from 'react-router-dom'
import './boxmenu.scss'
const Boxmenu = () => {
  return (
    <div className="boxmenu">
        <div className="boxmenu-container">
            <div className="boxmenu-text">
            <Link className='boxmenu-link' to="/contact" style={{textDecoration:"none"}}>
            <span>Free Consultation</span> 
            </Link>
            
            </div>
        </div>
    </div>
  )
}

export default Boxmenu