import React from 'react'
import './slider.scss'
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay ,	EffectFlip} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-flip';

import transport from 'assets/images/transport.jpg'
import face from 'assets/images/slide1.jpg'
import hair from 'assets/images/hair.jpg'
import doctors from 'assets/images/doctors.jpg'

const Slider = () => {
  return (
    <div className="slider">
        <div className='slider-title'>KADET CLINIC</div>
        <Swiper className='swiper'
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFlip]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={true}
        //effect= 'flip'
 
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
    >
        <SwiperSlide className='slide'><img className='slide-image' src={face} alt=''/></SwiperSlide>
        <SwiperSlide className='slide'><img className='slide-image' src={hair} alt=''/></SwiperSlide>
        <SwiperSlide className='slide'><img className='slide-image' src={transport} alt=''/></SwiperSlide>
        <SwiperSlide className='slide'><img className='slide-image' src={doctors} alt=''/></SwiperSlide>
    </Swiper>
    </div>
  )


}

export default Slider