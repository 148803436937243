import Form from 'components/form/Form'
import React from 'react'
import './freeconsultation.scss'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const Freeconsultation = () => {
  return (
    <div className="freeconsultation">
      <div className="freeconsultation-container">
        <div className="freeconsultation-left">
          <h2 className='freeconsultation-h2'>We Are in Service</h2>
         <h1 className='freeconsultation-header'>7/24 Hours</h1>
         <h2 className='freeconsultation-h2'>Kadet Clinic</h2>
         <h3 className='freeconsultation-h3'>Assistance</h3>
         <div className='freeconsultation-whatsapp'>
         <WhatsAppIcon className='freeconsultation-icon'/>
         <a href="https://wa.me/905376779908" target="_blank">+90 537 677 9908</a></div>
        </div>
        <div className="freeconsultation-right">
          <Form title="Free Consultation"/>
        </div>
      </div>
    
    </div>
  )
}

export default Freeconsultation