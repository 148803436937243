export const hairtransplantlist = [
  {
    id: 0,
    title: "Hair Transplant",
    text: (
      <p>
        Hair transplantation is one of the most required procedures nowadays due
        to new advanced techniques used by doctors. The first step is to extract
        the hair follicles from the back of the head between the head and above
        the "donor area" of the neck, and it is performed by a device called a
        "micro-motor" which is one of the best tools used in the extraction
        process. As for the other step, the opening of the channels using the
        “sapphire pen” and the opening of the channels will be done in a precise
        way that will be compatible with natural hair to ensure natural results
        for hair transplants. Then the extracted hair follicles will be kept in
        a special liquid and finally, after a pause, it will be the stage of
        transplantation.
        <br /> Why Hair Transplant in Turkey?
        <br /> Turkey must be the only country in the international hair
        transplant industry that combines high quality and economical prices in
        one place. As for KADET Clinique, we also offer our treatments at
        European and American standards to our patients coming from abroad under
        more economical conditions in Turkey. Note that the hair transplant
        procedure has been practiced in Turkey for several years.
      </p>
    ),
    image: "/assets/images/hairtransplant/0/0.jpg",
  },
];
export const dentalimplantslist = [
  {
    id: 0,
    title: "DENTAL IMPLANTS",
    text: (
      <p>
        Teeth that must exist in the mouth throughout human life are also lost
        due to insufficient care, genetic factors leading to cavities or gum
        disease. As a result of these losses, holes occur in the mouth, and as a
        result, the chewing function begins to break. Although mobile dentures
        allow chewing to some extent, they cause discomfort and aesthetic
        problems. Bridge systems may or may not cause contact damage to teeth
        used as abutments as a result of some etching. Implant systems have
        emerged to exchange teeth lost due to these negative reasons. The
        implant can be a titanium screw system used to make prostheses on the
        teeth. Dental implants placed in the jawbone function as a complex part
        of the body. In single-tooth deficiencies, it is possible to complete
        incomplete teeth without touching neighboring healthy teeth, again using
        dentures rather than mobile prostheses in multiple-tooth deficiencies.
        It takes about 10-15 minutes to do each implant surgery.
        <h3>ALL ABOUT FOUR IMPLANTS IN ONE DAY</h3>
        All on 4 dental implants are used for full mouth restorations for
        multiple tooth loss.The All On 4 implantation technique is a procedure
        to fix the same day dental prosthesis on four titanium dental implants
        which are placed at certain angles in patients with edentulous patients.
        These new technology implants are a quick and easy solution that makes
        replacing a full set of teeth a painless reality.
        <h3>ADVANTAGES OF ALL ON FOUR IMPLANTS</h3>
        Provides the ability to create a fixed dental prosthesis with same day
        surgery.
        <br /> The operation is simpler because no other surgical procedures
        such as sinus leveling surgery or bone addition are performed.
        <br /> The duration of implant surgery is shorter.
        <br /> A beautiful custom appearance and a laugh line give a smile
        aesthetic
        <br /> Patients who receive All-on-4 treatments end up with a full set
        of natural-looking teeth connected securely and comfortably in their
        mouth, allowing them to enjoy all their favorite foods, as well as the
        right kind of food. and drink, worry-free.
        <br /> One of the main advantages of the treatment is that the results
        are permanent
      </p>
    ),
    image: "/assets/images/dentistry/1/1.jpg",
  },
];

export const holywoodsmilelist = [
  {
    id: 0,
    title: "Hollywood Smile",
    text: (
      <p>
        Your smile is your first impression that you noticed after meeting
        someone, and it is also the first moves that affect your social life
        from the opposite side. The smile is not just about the lips, but also
        the teeth and gums behind, including the jaw line. Smile design focuses
        on improving the appearance of your face by giving it a totally new
        look. In recent years, Hollywood stars have started attracting attention
        with their extraordinarily beautiful and expressive smiles and hence the
        term “Hollywood Smile” has appeared. Hollywood smile treatment has been
        successfully performed for many years in Turkey.
        <h3>Porcelain Crown</h3>
        It is a solid material made from this porcelain. This durable material
        gives teeth a natural look and is smudge-proof. Especially on the
        implant, the porcelain crown is the most recommended type of coating.
        <h3>Zirconium Crown</h3>
        Zirconium teeth are the most effective quality products that have
        reached until today in terms of aesthetics, durability, tissue
        compatibility and natural vision which are the essential requirements of
        dentistry. The enamel of healthy teeth passes the sun completely. The
        metals that are the substructures of classic porcelain crowns create an
        opaque image in your teeth. It makes your teeth lifeless and artificial.
        This situation is prevented by a zirconium crown.
        <h3>FACETED Laminated Veneers</h3>
        You see it very often especially in famous people, but you can't help
        wondering "how does everyone have such shiny teeth". It is the method of
        thinning only the front faces by 0.3-0.7 mm without cutting the teeth
        and the tooth tissue is at least lost. Then the lamination of the facets
        is carried out. In addition to their aesthetic properties, laminates are
        very durable, long-lasting and powerful. in this case, they are a
        perfect aid to eliminate minor problems of your natural tooth. they are
        mainly used for the restoration of teeth in the anterior region.
      </p>
    ),
    image: "/assets/images/dentistry/0/0.jpg",
  },
];

export const gingivaldiseaseslist = [
  {
    id: 0,
    title: "Gingival Diseases",
    text: (
      <p>
        <h3>PERIODONTICS</h3>
        <b>GUM DISEASES PERIODONTICS</b> this is called gum disease and its
        treatment. The initial stage of gum disease is called gingivitis. The
        gums are also slightly red, often bleeding and swollen. Treatment for
        gum disease involves removing dirt from the teeth (plaque and dentitis
        and providing a precise root surface, followed by effective daily oral
        care. This process removes bacteria and irritants which cause
        inflammation of the gums.In the advanced stages of gum disease, gum
        disease is controlled by planning curettage and flap operations if
        necessary.The aim of this treatment is to clean out the teeth in the
        deep periodontal pockets surrounding the teeth, eliminate pocket
        contraction and provide a smooth root surface.After periodontal
        treatment, patients should be regularly examined by a professional
        dentist, control of dental plaque and removal of final dental calculi
        must be removed Gum disease is not cured with treatments such as
        antibiotics, mouthwash, vitamins, etc. Must be treated by a professional
        gum disease dentist
        <h3>ORTHODONTICS</h3>
        If you have problems with your bite or the spacing of your teeth, you
        will need professional orthodontic dental care. Orthodontics is the
        branch of dentistry that deals with abnormalities of the teeth and
        jawbone. Jagged, crooked teeth, overbite teeth, open teeth can be
        treated with orthodontics. Orthodontic treatments can also be performed
        with wireless orthodontics (invisalign) without using braces. In some
        treatments, wires can also be attached inside the teeth (incognito).
        <h3>TYPES OF DENTAL DISORDERS ON ORTHODONTIC TREATMENT </h3>
        <ul>
          <li>
            <b>OVERBITE:</b> An overbite occurs when your upper front teeth
            overlap with your lower front teeth. LOWER BITE: the lower teeth are
            too far forward or the upper teeth are too far back
          </li>

          <li>
            <b>CROSSBITE:</b> This is a dental disorder that affects the way
            your teeth are aligned. The most important thing for getting a
            crossbite is that the upper teeth fit behind your lower teeth when
            your mouth is closed or at rest.
          </li>
          <li>
            <b>OPEN BITE:</b> If your upper and lower teeth don't touch when
            your mouth is closed, your dentist calls it an open bite.
          </li>
          <li>
            <b>MISPLACED MIDLINE:</b> when the middle of your upper front teeth
            does not line up with the middle of your lower front teeth
          </li>
          <li>
            <b>SPACING:</b> spaces and areas between teeth due to missing teeth
            or teeth that do not "fill" the mouth
          </li>
          <li>
            <b>CROWDED TEETH:</b> Once you don't have enough empty space in your
            jawbone for your teeth to fit normally, your teeth can twist,
            intersect and crook.
          </li>
        </ul>
        <h3>WHAT IS THE BEST ORTHODONTIC TREATMENT?</h3>
        <b>INVISALIGN (WIRELESS ORTHODONTICS): </b>Orthodontic treatment is
        possible with the help of special transparent plates without using
        orthodontic appliances. You do not need to use braces with this
        treatment, which is carried out with transparent plates adapted to the
        teeth of each patient, with an effective and almost invisible aesthetic
        perception.
        <br />
        Invisalign, a digital orthodontic treatment technology, is a wireless
        orthodontic treatment applied to correct your teeth with transparent
        plates (aligners) adapted to your teeth. The most important reason for
        preference for invisalign, which offers wireless orthodontic treatment,
        is that it is not certain that you have received orthodontic treatment
        while correcting the bewilderment of your teeth.
        <br />
        Your doctor will measure your mouth and these measurements will be
        transferred to the computer environment with a 3D scanning method called
        CAD-CAM system. Teeth transferred to a virtual environment are moved by
        computer simulation. The transparent plate is produced according to each
        movement of 0.05 mm. Your Invisalign plates arrive at the International
        Plus Clinique 1.5 months after the three-dimensional scan of your mouth.
        <br />
        Invisalign plates are mass produced depending on the position where the
        teeth need to be moved. Each plate creates 0.05mm of movement. The
        plates are fixed for 15 days and then the other plate is passed.
        Although it is applied in many cases, your dentist must decide if it is
        right for you.
        <br />
        <br />
        <b>INVISIBLE STRAPS:</b> Thanks to the developing technology, the
        supports and metal wires of gray color have been replaced by transparent
        and visible spacers. Thus, patients can complete their treatment without
        any aesthetic concern.
      </p>
    ),
    image: "/assets/images/dentistry/2/2.jpg",
  },
];

export const orthodonticslist = [
  {
    id: 0,
    title: "Orthodonticss",
    text: (
      <p>
        If you have problems with your bite or the spacing of your teeth, you
        will need professional orthodontic dental care. Orthodontics is the
        branch of dentistry that deals with abnormalities of the teeth and
        jawbone. Jagged, crooked teeth, overbite teeth, open teeth can be
        treated with orthodontics. Orthodontic treatments can also be performed
        with wireless orthodontics (invisalign) without using braces. In some
        treatments, wires can also be attached inside the teeth (incognito).
        <br />
        <h3>Types of Dental Disorders on Orthodontic Treatment</h3>
        Overbite: An overbite occurs when your upper front teeth overlap with
        your lower front teeth.
        <br /> Lower bite: the lower teeth are too far forward or the upper
        teeth are too far back
        <br /> Crossbite: It is a dental disorder that affects the way your
        teeth are aligned. The most important thing for getting a crossbite is
        that the upper teeth fit behind your lower teeth when your mouth is
        closed or at rest.
        <br /> Open bite: If your upper and lower teeth don't touch when your
        mouth is closed, your dentist calls it an open bite.
        <br /> Misplaced midline: when the middle of your upper front teeth does
        not line up with the middle of your lower front teeth
        <br /> Spacing: spaces and areas between teeth due to missing teeth or
        teeth that do not “fill” the mouth
        <br /> Crowded teeth: Once you don't have enough empty space in your
        jawbone for your teeth to fit normally, your teeth can twist, intersect
        and crook.
        <br /> What Is The Best Orthodontic Treatment?
        <br /> Invisalign (Wireless Orthodontics)
        <br /> Orthodontic treatment is possible with the help of special
        transparent plates without using orthodontic appliances. You do not need
        to use braces with this treatment, which is carried out with transparent
        plates adapted to the teeth of each patient, with an effective and
        almost invisible aesthetic perception.
        <br /> Invisalign, a digital orthodontic treatment technology, is a
        wireless orthodontic treatment applied to correct your teeth with
        transparent plates (aligners) adapted to your teeth. The most important
        reason for preference for invisalign, which offers wireless orthodontic
        treatment, is that it is not certain that you have received orthodontic
        treatment while correcting the bewilderment of your teeth.
        <br /> Your doctor will measure your mouth and these measurements will
        be transferred to the computer environment with a 3D scanning method
        called CAD-CAM system. Teeth transferred to a virtual environment are
        moved by computer simulation. The transparent plate is produced
        according to each movement of 0.05 mm. Your Invisalign plates arrive at
        the International Plus Clinique 1.5 months after the three-dimensional
        scan of your mouth.
        <br /> Invisalign plates are mass produced depending on the position
        where the teeth need to be moved. Each plate creates 0.05mm of movement.
        The plates are fixed for 15 days and then the other plate is passed.
        Although it is applied in many cases, your dentist must decide if it is
        right for you.
        <br /> Invisible Straps
        <br /> Thanks to the developing technology, the supports and metal wires
        of gray color have been replaced by transparent and visible spacers.
        Thus, patients can complete their treatment without any aesthetic
        concerns.
      </p>
    ),
    image: "/assets/images/dentistry/3/3.jpg",
  },
];

export const liposuctionlist = [
  {
    id: 0,
    title: "VASER LIPOSUCTION",
    text: (
      <p>
        People who are overweight, but cannot eliminate fat in certain parts of
        their body, even when dieting and exercising. Now you can eliminate fat
        with Vaser liposuction. Although liposuction is effective in removing
        fat, it does not help with overall weight loss. With this procedure, the
        body will get a much better shape by removing fatty tissues. It is
        possible to suck fat from the abdomen, buttocks, hips, knees, legs, arms
        and breasts. This procedure makes the body more symmetrical and natural.
        After performing the procedure, if the body has gained fat, the removal
        of fatty tissue does not affect the abdominal region.
        <br /> Liposuction has become more popular among men and women, which
        involves removing fat cells under the skin. Although liposuction is not
        a treatment for obesity.
        <h3>
          <b>Why Do Liposuction?</b>
        </h3>
        Lots of concerted fat suffering in certain parts of the body,
        liposuction is the perfect solution for this problem and men who also
        suffer from gynecomastia, Turkey is the top country in overweight
        surgical and non-surgical operations, which is famous for the most
        qualified plastic surgeons. You must very well choose the doctor and
        also the hospital, as with other surgical operations. When choosing a
        cosmetic hospital, you need to check their results. Since experience
        affects the success rate of the operation, it is better to choose
        hospitals that contain experienced doctors
      </p>
    ),
    image: "/assets/images/bodysurgery/0/0.jpg",
  },
];

export const tummytuncklist = [
  {
    id: 0,
    title: "TUMMY TUCK",
    text: <p></p>,
    image: "/assets/images/bodysurgery/1/1.jpg",
  },
];

export const bbllist = [
  {
    id: 0,
    title: "BRAZILIAN BUTT LIFT",
    text: (
      <p>
        <b>The Brazilian butt lift operation</b> is one of the most requested
        surgeries of the last decade due to the big change in beauty standards.
        And after the new advanced technologies nowadays, it has been affordable
        and more quality-based operations. All women aim to have a perfect body
        to increase their own self-esteem. and to have a more attractive female
        figure, many women tend to have a Brazilian butt lift. Luckily, Kadet
        Clinic in Turkey provides the operation with an experienced team of
        doctors who provide affordable and quality operation with the desired
        results. Here is information about the operation to give our customers
        an overview of the operation.
        <br />
        <h3>
          <b>Why Do A Brazilian Butt Lift?</b>
        </h3>
        Before the operation, we provide a free consultation for our clients so
        that the doctor asks you questions about the forms and your expectations
        about the operation to have the best possible results. Most breast
        augmentation sizes depend on height, weight, ribcage and shoulder width.
        Here are the pre-surgery guidelines you should follow:
        <ul>
          <li> Women who have sagging buttocks due to weight loss.</li>
          <li>Women who have flat buttocks.</li>
          <li>Women with small buttocks for genetic reasons.</li>
          <li>
            Women whose buttocks are small compared to other parts of the body.{" "}
          </li>
        </ul>
        The doctors at kadet clinic take each case into consideration and offer
        a treatment adapted to each case encountered. And we provide
        preoperative examination to ensure the health of our patients",
      </p>
    ),
    image: "/assets/images/bodysurgery/7/7.jpg",
  },
];

export const abdominoplastylist = [
  {
    id: 0,
    title: "ABDOMINOPLASTY",
    text: (
      <p>
        Our outward appearance is the main factor in how people perceive us and
        how we feel. So, a fit and well-trained body is the way to regain
        confidence and self-esteem. And sometimes certain things stand in our
        way to get the perfect look. One of those things is sagging in the
        abdomen area which is a very unattractive feature to have.
        <br /> Luckily, Kadet clinic provides abdominoplasty, and it is
        performed by our team of experienced doctors who will provide our
        clients with professional and quality treatment. Doing surgery is
        something that needs to be done with care, psychological or surgical, so
        we will provide you with information that will help you have a thorough
        knowledge of the surgery.
        <h3>
          <b>
            What Are The Reasons That Lead To Performing Abdominoplasty Surgery?
          </b>
        </h3>
        Several factors lead people to perform the surgery and some of these
        reasons are:
        <ul>
          <li>Sagging after pregnancy.</li>
          <li>Sagging after losing weight.</li>
          <li>Cracks in the abdominal region.</li>
          <li>Male abdomen swaying.</li>
        </ul>
        <br /> The doctors at kadet clinic take each case into consideration and
        provide appropriate treatment for each case they encounter. And we
        provide preoperative examination to ensure the health of our patients.
      </p>
    ),
    image: "/assets/images/bodysurgery/1/1.jpg",
  },
];

export const mammalaugmentationlist = [
  {
    id: 0,
    title: "Mammal augmentation",
    text: (
      <p>
        Breast augmentation is one of the most performed surgeries in recent
        decades due to the advancement in technologies used in breast
        augmentation surgery. Undoubtedly, a proportionate and attractive body
        is the ultimate goal for all women around the world and can be achieved
        with plastic surgeries easily and affordably these days.
        <br /> kadet clinic offers breast augmentation surgery performed by our
        experienced team of doctors who will provide our clients with quality
        and professional treatment. So here is some information about Brest
        Augmentation Surgery to give you an overview of the surgery
        <br /> Why Breast Augmentation
        <br /> Apart from the need to have a beautiful female body, there are
        factors other than psychological factors that lead women to breast
        augmentation. Some factors like:
        <br /> Small breast size after removing breast tissue due to breast
        cancer treatment.
        <br /> Small breast size after pregnancy or weight loss.
        <br /> Disproportionate breasts.
        <br /> Inconsistency between breast size and other body parts.
        <br /> Naturally small chest from birth.
        <br /> Failed surgery.
        <br /> Kadet's doctors take each case into consideration and offer a
        treatment adapted to each case encountered. And we provide preoperative
        examination to ensure the health of our patients
      </p>
    ),
    image: "/assets/images/bodysurgery/7/7.jpg",
  },
];

export const breastliftlist = [
  {
    id: 0,
    title: "Breast Lift",
    text: (
      <p>
        <b>Breast augmentation</b> is one of the most performed surgeries in
        recent decades due to the advancement in technologies used in breast
        augmentation surgery. Undoubtedly, a proportionate and attractive body
        is the ultimate goal for all women around the world and can be achieved
        with plastic surgeries easily and affordably these days.
        <br />
        <br /> kadet clinic offers breast augmentation surgery performed by our
        experienced team of doctors who will provide our clients with quality
        and professional treatment. So here is some information about Brest
        Augmentation Surgery to give you an overview of the surgery
        <br />
        <h3>
          <b> Why Breast Augmentation?</b>
        </h3>
        <br /> Apart from the need to have a beautiful female body, there are
        factors other than psychological factors that lead women to breast
        augmentation. Some factors like:
        <ul>
          <li>
            Small breast size after removing breast tissue due to breast cancer
            treatment.
          </li>
          <li>Small breast size after pregnancy or weight loss.</li>
          <li>Disproportionate breasts.</li>
          <li>Inconsistency between breast size and other body parts.</li>
          <li>Naturally small chest from birth.</li>
          <li>Failed surgery.</li>
        </ul>
        Kadet's doctors take each case into consideration and offer a treatment
        adapted to each case encountered. And we provide preoperative
        examination to ensure the health of our patients
        <br />
      </p>
    ),
    image: "/assets/images/bodysurgery/4/4.jpg",
  },
];

export const breastreductionlist = [
  {
    id: 0,
    title: "Breast reduction",
    text: (
      <p>
        <b>Breast reduction </b>is the process of reducing the volume of the
        breast for several reasons. Breast reduction is well known to women who
        aim to have the perfect body shape that will allow them to have more
        self-confidence which will ultimately affect their social and
        professional life.
        <h3>
          <b>Why Breast Reduction?</b>
        </h3>
        Multiple reasons lead to breast reduction and some of them can be
        psychological or physical and all the reasons that lead to the operation
        will be discussed by doctors and given special treatment. Some of these
        reasons are:
        <ul>
          <li>Back and neck pain caused by overweight breasts.</li>
          <li>Inconsistency in size of breasts and other body parts.</li>
          <li>Sweating and rashes under the breasts.</li>
          <li>
            Pain in the shoulders due to the large size of the chest compared to
            them.
          </li>
          <li>Difficult to find a fabric adapted to the size of the chest.</li>
        </ul>
      </p>
    ),
    image: "/assets/images/bodysurgery/5/5.jpg",
  },
];

export const gynecomastialist = [
  {
    id: 0,
    title: "Gynecomastia",
    text: (
      <p>
        <b>Gynecomastia</b> is a very common problem in men after reaching the
        age of puberty and it occurs due to the imbalance of hormonal
        distribution in the body of men. And this is a very unattractive feature
        for a man and it affects his psychological statues and his social life.
        <br />
        <br /> At kadet clinic in Turkey, we provide you with the best
        techniques used in the cosmetics industry. With a well-rounded team of
        experienced doctors. This will provide our patients with professional
        treatment with worldwide standards. Here is information on gynecomastia
        to give clients an overview of the operation
        <br />
        <h3>
          <b>What Are The Factors For Performing Gynecomastia Surgery?</b>
        </h3>
        Several reasons lead to perform gynecomastia surgery and one of the main
        factors are certainly the psychological factors that lead men to feel
        less confident in their outward appearance and other medical reasons
        such as the following:
        <ul>
          <li>Breast size difference</li>
          <li>Chest pain.</li>
          <li>Feeling a lump under the nipples.</li>
          <li>Swollen breast tissue.</li>
          <li>Hanging nipples.</li>
        </ul>
        <br /> The doctors at kadet clinic take each case into consideration and
        offer a treatment adapted to each case encountered. And we provide
        preoperative examination to ensure the health of our patients.
      </p>
    ),
    image: "/assets/images/bodysurgery/6/6.jpg",
  },
];

export const armliftlist = [
  {
    id: 0,
    title: "Brachioplasty (Arm Lift)+",
    text: (
      <p>
        <b>Arm lift surgery</b> is one of the simplest cosmetic surgeries and it
        usually follows liposuction in order to have a coordinated body shape.
        We all aim to have the perfect summer body shape for hitting the beach
        and having body confidence will help bring positivity into our social
        lives. And without a doubt, having sagging arms is one of the obstacles
        that stand between people and achieving perfect shape.
        <br />
        <br /> Kadet clinic will help you get rid of this problem and provide
        you with a well-rounded team of doctors who will provide you with the
        best medical treatment with the best techniques founded in the cosmetic
        industry.
        <br />
        <h3>What Are The Factors That Lead To Arm Lift Surgery?</h3>
        There are many reasons that encourage arm lift surgery and some of them
        are psychological or physical factors:
        <ul>
          <li>The unattractive shape of the arms.</li>
          <li>Lack of confidence in the appearance of the arms.</li>
          <li>Specific accidents that cause the arms to sag.</li>
          <li>Skin rashes and inflammation.</li>
          <li>To be compatible with other parts of the body.</li>
        </ul>
        And every factor is considered by our doctor and he will receive his
        special treatment.
      </p>
    ),
    image: "/assets/images/bodysurgery/8/8.jpg",
  },
];

export const thighliftlist = [
  {
    id: 0,
    title: "Thighplasty (Inner Thigh Lift)",
    text: (
      <p>
        <b>Inner thigh lift</b> is a procedure that usually follows weight loss
        or weight gain and is also usually performed after pregnancy due to the
        resulting bodily changes. And touching thighs affects the psychological
        status of people due to the feeling of discomfort during walking and
        other activities.
        <br /> Fortunately, inner thigh lift is one of the easiest surgeries
        these days due to the advanced technologies used to operate.
        International Plus puts at your disposal the best team of experienced
        doctors who will offer you quality and professional treatment during all
        phases of the operation.
        <h3>Why Inner Thigh Lift?</h3>
        There are several reasons why people have inner thigh lifts. Some
        reasons like:
        <ul>
          <li>
            Inconsistent shape of the thighs compared to other parts of the
            body.
          </li>
          <li>
            The rashes resulted in the touching of the thighs while walking.
          </li>
          <li>The inability to find a suitable fabric.</li>
          <li>Vast amount of sag.</li>
          <li>Cellulite.</li>
        </ul>
        And every factor is considered by our doctor and he will receive his
        special treatment.
      </p>
    ),
    image: "/assets/images/bodysurgery/9/9.jpg",
  },
];

export const fuelist = [
  {
    id: 0,
    title: "FUE Technique",
    text: (
      <p>
        <b>FUE (Follicles Unit Extraction) method</b>, which is operated using
        advanced technology used in hair transplantation. In this method, a
        device called a micromotor is used to extract hair follicles from the
        donor area. Thanks to this device, the graft extraction process takes
        little time and will avoid swelling of the donor area. In the hair
        transplantation process performed with the FUE method, the number of
        transplanted hair follicles and the desired result are achieved at 95%.
        <br /> Once the area is prepared for the procedure, the hair follicles
        are collected one by one. Each of these roots, called a graft, has an
        average of 2-3 hairs. Small incisions are made to transfer the collected
        hair follicles one by one to the bald area.
        <br /> The size and depth of these incisions must be compatible with the
        grafts. The angle of the open channels should be adjusted very well to
        capture the natural look. The roots taken at this last stage are
        carefully placed one by one in the open channels
        <br /> In other words, hair transplantation is the process of
        transferring healthy and strong hair follicles usually taken from the
        back of the head to be transferred to the balding area.
        <h3>
          <b>WHY SHOULD I CHOOSE THE FUE TECHNIQUE?</b>
        </h3>
        The FUE method is the latest technique used since 2004.
        <br />
        <br /> Compared to the old method, it is a much more comfortable
        operation for the patient and the doctor.
        <br /> No additional time is required to separate the roots.
        <br /> No additional time is required to separate the grafts. They are
        clean and free of residues taken in the FUE technique, so it is possible
        to open smaller channels.
        <br /> In this way, there is no trace in the cultivation area. The
        patient is provided with a casual hat without a bandage on the head.
        <br /> The patient can do office work the next day. It is also possible
        for the patient to return to work immediately after the first wash
        (about 36 hours later).
        <br />
      </p>
    ),
    image: "/assets/images/hairtransplant/1/1.jpg",
  },
];

export const dhilist = [
  {
    id: 0,
    title: "SAPPHIRE Technique",
    text: (
      <p>
        <b>Sapphire Hair Transplant</b> is a new hair transplant technique,
        which is used to open channels using natural gemstone i.e. sapphire
        crystal, and does not harm the texture of the hair. skin.
        <br /> Sapphire Hair Transplant is a technique to open a channel and has
        a sapphire shape as its name.
        <br /> In other grooving methods, the slitting method, namely plain and
        metal, is used, while the sapphire technique uses natural minerals and
        precious stones.
        <br /> No damage was found in hair transplants performed with the
        sapphire technique. Thus, the sapphire technique is very safe in terms
        of health.
        <br /> Thanks to sapphire hair transplantation, there is less bleeding
        in hair transplantation and it heals faster and gives more natural
        results.
        <h3>
          <b>WHY SHOULD I CHOOSE THE SAPPHIRE TECHNIQUE?</b>
        </h3>
        Since sapphire is a natural element, it does not cause any damage to the
        skin.
        <br /> The healing speed of Sapphire Hair Transplant is much faster.
        <br /> The pain is much less during the sapphire technique hair
        transplant.
        <br /> High rate of naturalization of grafts.
        <br /> The probability of infection is nil in sapphire hair
        transplantation.
        <br />
        The materials used in the sapphire technique are disposable and sterile
        and personal.
        <br /> In hair transplantation, intensive and thick hair results are
        easier with the sapphire technique.
        <br />
      </p>
    ),
    image: "/assets/images/hairtransplant/2/2.jpg",
  },
];

export const sapphirelist = [
  {
    id: 0,
    title: "DHI Technique",
    text: (
      <p>
        <b> DHI hair transplantation technique</b> is a slightly more developed
        version of FUE hair transplantation technique regarding the absorption
        and transplantation of hair follicles. The grafts (hair follicles) are
        extracted from the donor area (rarely beard or chest) using micro-motors
        and planted in the balding area.
        <br /> The difference between DHI hair transplant technique and other
        techniques starts after this point. The special technique used in the
        DHI hair transplant technique works with much more advanced technology
        than the techniques available today. In this technique, the first
        objective is to perform a hair transplant operation guaranteed for life
        without using scalpels or stitches and without making holes in the
        scalp.
        <br /> With this procedure, which is carried out in a single session, it
        is possible to have more intense and natural hair. The main difference
        in the application of the technique is the use of a hair transplant
        board developed in recent years. The grafts are taken with the surgical
        pen, the thickness of which varies between 0.6 mm and 1 mm, and used in
        the scalp without opening stitches.
        <br /> This technique, which requires more attention, precision and
        effort than other
        <br /> methods, offers many advantages that cannot be ignored.
        <h3>
          <b>WHY SHOULD I CHOOSE THE DHI TECHNIQUE?</b>
        </h3>
        <ul>
          <li>
            It minimizes the length of hair follicles remaining outside the
            tissue and allows for healthier transplantation.
          </li>
          <li>Hair roots have a higher survival rate.</li>
          <li>
            As the incision area will be smaller, this minimizes the risk of
            bleeding as much as possible.
          </li>
          <li>
            Faster recovery can be seen after the hair transplant operation, so
            the patient can get back to their daily routine in a short time.
          </li>
        </ul>
        <b>
          With this method, hair transplantation without shaving can be
          performed more easily.
        </b>
      </p>
    ),
    image: "/assets/images/hairtransplant/0/0.jpg",
  },
];

export const gastricballoonlist = [
  {
    id: 0,
    title: "Gastric balloon",
    text: (
      <p>
        <b>The gastric balloon</b> procedure is a non-surgical procedure related
        to obesity or weight loss in which a medical balloon is inserted into
        the stomach to temporarily reduce the capacity of the stomach. This will
        help the patient to follow a diet without encountering any problems.
        <br />
        <p>
          <b>Why should i Make A Gastric Balloon?</b>
          <br /> There are several reasons that lead to having a gastric balloon
          performed, and here are some of the important reasons:
          <ul>
            <li>Difficulties in breathing.</li>
            <li>High blood pressure.</li>
            <li>High cholesterol level Chronic knee, waist and joint pain.</li>
            <li>Psychological factors due to body shape.</li>
          </ul>
          <br /> The doctors at Kadet Clinique take each case into consideration
          and offer a treatment adapted to each situation encountered. And we
          provide preoperative examination to ensure the health of our patients.
          <br /> Due to the advancement of technologies used in bariatric
          operations, the operation has become more reliable and the recovery
          periods faster.
          <br />
          <br /> Kadet clinic provides a well-rounded team of doctors with years
          of experience in the field of bariatric surgeries who will provide you
          with quality and professional medical treatment.
        </p>
      </p>
    ),
    image: "/assets/images/bariatric/0/0.jpg",
  },
];

export const sleevegastrectomylist = [
  {
    id: 0,
    title: "Sleeve gastrectomy",
    text: (
      <p>
        <b>Sleeve gastrectomy</b> surgery is an obesity reduction procedure that
        aims to minimize the size of the stomach. Obesity leads people to
        contrary psychological statues and also affects their social life which
        will lead to low self-confidence and low self-esteem especially after
        trying several diets and result in failure.
        <br /> Fortunately, due to the advancement of technologies used in
        bariatric operations, the operation has become more reliable and the
        recovery periods faster.
        <br /> kadet clinic provides a well-rounded team of doctors with years
        of experience in the field of bariatric surgeries who will provide you
        with quality and professional medical treatment.
        <br />
        <br />
        <h3>
          <b>When To Do A Gastric Sleeve?</b>
        </h3>
        Many problems are solved by sleeve gastrectomy surgery. Being overweight
        is one of the important factors that will lead people to perform sleeve
        gastrectomy due to their fear of developing diseases caused by obesity
        like high blood pressure and diabetes also, back pain and deviation bone
        caused by the high pressure on these areas. Psychological reasons are
        also important factors, as obese people tend to avoid social events,
        which will lead to low self-esteem and depression. Also, people with
        high BMI levels tend to undergo sleeve gastrectomy.
        <br />
        BMI (Body Mass Index) from 35 to 39.9 (obesity), 40 or more (extreme
        obesity).
      </p>
    ),
    image: "/assets/images/bariatric/1/1.jpg",
  },
];

export const rhinoplastylist = [
  {
    id: 0,
    title: "Rhinoplasty",
    text: (
      <p>
        Commonly known as a nose job, is a plastic surgery procedure for
        altering and reconstructing the nose. There are two types of plastic
        surgery used – reconstructive surgery that restores the form and
        functions of the nose and cosmetic surgery that changes the appearance
        of the nose.
      </p>
    ),
    image: "/assets/images/facialsurgery/0/0.jpg",
  },
];

export const facelifttechnique = [
  {
    id: 1,
    title: "Face-lift technique",
    text: (
      <p>
        A face-lift (rhytidectomy) is a cosmetic surgical procedure to create a
        younger appearance in your face. The procedure can reduce the sagging or
        folds of skin on the cheeks and jawline and other changes in the shape
        of your face that occur with age.
      </p>
    ),
    image: "/assets/images/facialsurgery/1/1.jpg",
  },
];

export const otoplasty = [
  {
    id: 2,
    title: "Otoplasty",
    text: (
      <p>
        Also known as cosmetic ear surgery — is a procedure to change the shape,
        position or size of the ears. You might choose to have otoplasty if
        you're bothered by how far your ears stick out from your head. You might
        also consider otoplasty if your ear or ears are misshapen due to an
        injury or birth defect.
      </p>
    ),
    image: "/assets/images/facialsurgery/2/2.jpg",
  },
];

export const necklift = [
  {
    id: 3,
    title: "Neck lift",
    text: (
      <p>
        Is a cosmetic procedure that removes excess skin and fat around your jaw
        line, creating a more defined and youthful-looking neck.
      </p>
    ),
    image: "/assets/images/facialsurgery/7/7.jpg",
  },
];

export const genioplasty = [
  {
    id: 4,
    title: "Genioplasty",
    text: (
      <p>
        Called Chin surgery , is a surgical procedure to reshape the chin either
        by enhancement with an implant or reduction surgery on the bone.
      </p>
    ),
    image: "/assets/images/facialsurgery/8/8.jpg",
  },
];

export const eyebrowlift = [
  {
    id: 4,
    title: "Eyebrowlift",
    text: (
      <p>
        A brow lift is a cosmetic procedure to raise the brows. It's also known
        as a forehead lift or forehead rejuvenation. A brow lift improves the
        appearance of the forehead, the brow and the area around the eyes. The
        procedure involves raising the soft tissue and skin of the forehead and
        brow
      </p>
    ),
    image: "/assets/images/facialsurgery/3/3.jpg",
  },
];

export const eyelidsurgery = [
  {
    id: 4,
    title: "Eyelid surgery",
    text: (
      <p>
        Eyelid surgery (blepharoplasty) is cosmetic surgery to remove excess
        skin or fat from the eyelids. The aim is to improve hooded or drooping
        eyelids or eye bags. Before you go ahead, be sure about your reasons for
        wanting eyelid surgery
      </p>
    ),
    image: "/assets/images/facialsurgery/4/4.jpg",
  },
];

export const facefatinjection = [
  {
    id: 4,
    title: "Face fat injection",
    text: (
      <p>
        Fat grafting is a procedure to restore youthful facial volume that is
        often lost due to age, gravity, environmental damage and sun exposure.
        Fat grafting is a rejuvenation treatment that uses your natural fat
        cells to fill pronounced lines and hollow areas of the face.
      </p>
    ),
    image: "/assets/images/facialsurgery/6/6.jpg",
  },
];

export const neckliposuction = [
  {
    id: 4,
    title: "Neck liposuction",
    text: (
      <p>
        Neck liposuction is a surgical procedure used to remove excess fat in
        order to shape and contour the neck, chin, and jawline. With neck
        liposuction, Little Rock patients can get rid of their double chin and
        restore a more proportional facial profile.
      </p>
    ),
    image: "/assets/images/facialsurgery/7/7.jpg",
  },
];
