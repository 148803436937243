import React from "react";
import "./callnow.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
const Callnow = () => {
  return (
    <div className="callnow">
      <div className="callnow-container">
        <div className="callnow-left">
          <div className="callnow-phone-text">
            Professional services with <b>KADET CLINIC</b> TURKEY
          </div>
          <div className="callnow-message-text">
            For more info call us or please click "GET INFORMATION" button...
          </div>
        </div>
        <div className="callnow-right">
          <div className="callnow-phone">
            <LocalPhoneIcon />
            <a href="tel:+90 537 677 9908">Call now</a>
          </div>
          <Link
            className="navbar-link"
            to="/contact"
            hash="#about"
            style={{ textDecoration: "none" }}
          >
            <div className="callnow-message">
              <EmailIcon />
              Leave a message
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Callnow;
