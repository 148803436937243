import React from 'react'
import Contacts from 'components/contacts/Contacts' 
import './footer.scss'
import Socialmedia from 'components/socialmedia/Socialmedia'
import Mainmenu from 'components/mainmenu/Mainmenu'
import Bookmenu from 'components/bookmeu/Bookmenu'
import Floatingbutton from 'components/floatingbutton/Floatingbutton'
import Boxmenu from 'components/boxmenu/Boxmenu'
const Footer = () => {
  return (
    <div className="footer">
      <div className="container-footer">
        <div className="contacts-footer">
        <Floatingbutton/>
        <Boxmenu/>
          <Contacts/>
          <Socialmedia/>
          </div> 
          <Mainmenu/>
          <Bookmenu/>
        </div>
    </div>
  )
}

export default Footer