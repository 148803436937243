import React from "react";
import "./single1.scss";
import Navbar from "components/navbar/Navbar";
import Footer from "containers/footer/Footer";
import {
  hairtransplantlist,
  liposuctionlist,
  holywoodsmilelist,
  abdominoplastylist,
  breastliftlist,
  breastreductionlist,
  gynecomastialist,
  armliftlist,
  thighliftlist,
  bbllist,
  gingivaldiseaseslist,
  dentalimplantslist,
  dhilist,
  fuelist,
  SAPPHIRElist,
  sapphirelist,
  orthodonticslist,
  gastricballoonlist,
  sleevegastrectomylist,
  rhinoplastylist,
  facelifttechnique,
  otoplasty,
  necklift,
  genioplasty,
  eyebrowlift,
  eyelidsurgery,
  facefatinjection,
  neckliposuction,
} from "utils/text";

const Single1 = ({ title }) => {
  var listOfImages;

  switch (title) {
    case "holywoodsmile":
      {
        listOfImages = holywoodsmilelist;
      }
      break;

    case "gingivaldiseases":
      {
        listOfImages = gingivaldiseaseslist;
      }
      break;

    case "dentalimplants":
      {
        listOfImages = dentalimplantslist;
      }
      break;

    case "orthodontics":
      {
        listOfImages = orthodonticslist;
      }
      break;
    //
    case "gastricballoon":
      {
        listOfImages = gastricballoonlist;
      }
      break;

    case "sleevegastrectomy":
      {
        listOfImages = sleevegastrectomylist;
      }
      break;

    //
    case "hairtransplant":
      {
        listOfImages = hairtransplantlist;
      }
      break;

    case "dhi":
      {
        listOfImages = dhilist;
      }
      break;

    case "fue":
      {
        listOfImages = fuelist;
      }
      break;

    case "sapphir":
      {
        listOfImages = sapphirelist;
      }
      break;
    //
    case "liposuction":
      {
        listOfImages = liposuctionlist;
      }
      break;

    case "bbl":
      {
        listOfImages = bbllist;
      }
      break;

    case "tummytunck":
      {
        listOfImages = abdominoplastylist;
      }
      break;

    case "breastlift":
      {
        listOfImages = breastliftlist;
      }
      break;

    case "breastreduction":
      {
        listOfImages = breastreductionlist;
      }
      break;

    case "gynecomastia":
      {
        listOfImages = gynecomastialist;
      }
      break;

    case "liposuction":
      {
        listOfImages = liposuctionlist;
      }
      break;

    case "armlift":
      {
        listOfImages = armliftlist;
      }
      break;

    case "thighlift":
      {
        listOfImages = thighliftlist;
      }
      break;

    case "rhinoplasty":
      {
        listOfImages = rhinoplastylist;
      }
      break;

    case "facelift":
      {
        listOfImages = facelifttechnique;
      }
      break;

    case "otoplasty":
      {
        listOfImages = otoplasty;
      }
      break;

    case "necklift":
      {
        listOfImages = necklift;
      }
      break;

    case "chin":
      {
        listOfImages = genioplasty;
      }
      break;

    case "eyebrowlift":
      {
        listOfImages = eyebrowlift;
      }
      break;

    case "eyelid":
      {
        listOfImages = eyelidsurgery;
      }
      break;

    case "facefat":
      {
        listOfImages = facefatinjection;
      }
      break;

    case "neckliposuction":
      {
        listOfImages = neckliposuction;
      }
      break;
  }

  return (
    <div className="single1">
      <Navbar />
      <div className="single-container1">
        {listOfImages.map((e) => (
          <div className="single-container2">
            <div className="single-image-container1" key={e.id}>
              <div className="single-title1">{e.title}</div>
              <img src={e.image} alt="" className="single-image1" />
            </div>

            <div className="single-text-container1">
              <div className="single-text1">
                <div className="single-p1">{e.text}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Single1;
