import React from 'react'
import { Link } from 'react-router-dom'
import './bookmenu.scss'
const Bookmenu = () => {
  return (
    <div className="bookmenu">
      <div>
        <span className="title">BOOK A RESERVATION</span>
          <p>For an appointment <br/> click the button below.</p>
          <p>We will call you back,<br/> as soon as possible for your request.</p>
          <Link className='navbar-link' to="/reserve" style={{textDecoration:"none"}}>
            <button className='book'>Book a reservation</button>
          </Link>
       
      </div>
       
    </div>
  )
}

export default Bookmenu