import Callnow from 'components/callnow/Callnow'
import Navbar from 'components/navbar/Navbar'
import Ourservices from 'components/ourservices/Ourservices'
import Whoweare from 'components/whoweare/Whoweare'
import Footer from 'containers/footer/Footer'
import React from 'react'
import './aboutus.scss'
const Aboutus = () => {
  return (
    <div className="aboutus">
        <Navbar/>
        <Whoweare/>
        <Ourservices/>
        <Callnow/>
        <Footer/>
    </div>
  )
}

export default Aboutus