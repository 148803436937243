
import Callnow from 'components/callnow/Callnow'
import Featured from 'components/featured/Featured'
import Firstvisit from 'components/firstvist/Firstvisit'
import Floatingbutton from 'components/floatingbutton/Floatingbutton'
import Navbar from 'components/navbar/Navbar'
import Slider from 'components/slider/Slider'
import About from 'containers/about/About'
import Footer from 'containers/footer/Footer'
import Freeconsultation from 'containers/freeconsltation/Freeconsultation'
import Testamenial from 'containers/testamenial/Testamenial'
import React from 'react'
import './home.scss'
const Home = () => {
  const about = document.querySelector(".about")
  const testamenial = document.querySelector(".about")
  const footer = document.querySelector(".container-footer")

  window.addEventListener("scroll", ()=>{
    let offsetY = window.scrollY
    let offsetX = window.scrollX
    //about.style.transform= `translateY(${offsetY * 0.1}px)`
    //testamenial.style.transform= `translate(${offsetY * 0.4}px, ${offsetY * 0.7}px)`
    //footer.style.transform= `translateX(${offsetY * 0.1}px)`
  })
  
  return (
    <div className="home">
        <Navbar/>
        <Slider/>
        <Featured/>
        <Firstvisit/>
        <Freeconsultation/>
        <About/>
        <Callnow/>
        <Testamenial/>
        <Footer/>
        
    </div>
  )
}

export default Home